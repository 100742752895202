import { mainnet } from '../../constants'

const pools = mainnet.POOLS
const tokens = mainnet.TOKENS
const liq = mainnet.LIQ

export default [
  {
    address: '0x0',
    inputTokenAddress: pools.YT_WXBTRFLY_21_APR_2022_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_SLP_PENDLE_WETH_X_PENDLE,
    inputTokenAddress: pools.YT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_SLP_USDC_WETH_X_USDC,
    inputTokenAddress: pools.YT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.PENDLE_AAVE_LIQUIDITY_MINING,
    inputTokenAddress: pools.YT_AUSDC_30_DEC_2021_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.PENDLE_AAVE_LIQUIDITY_MINING,
    inputTokenAddress: pools.YT_AUSDC_29_DEC_2022_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.PENDLE_COMPOUND_LIQUIDITY_MINING,
    inputTokenAddress: pools.YT_CDAI_30_DEC_2021_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.PENDLE_COMPOUND_LIQUIDITY_MINING,
    inputTokenAddress: pools.YT_CDAI_29_DEC_2022_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
]
