import { avalanche } from '../../constants'

const pools = avalanche.POOLS
const tokens = avalanche.TOKENS
const liq = avalanche.LIQ

export default [
  {
    address: liq.YT_JLP_WAVAX_PENDLE_X_PENDLE,
    inputTokenAddress: pools.YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    contractType: 'PendleLiquidityMining',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_QIUSDC_X_USDC,
    inputTokenAddress: pools.YT_QIUSDC_28_DEC_2023_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_QIAVAX_X_USDC,
    inputTokenAddress: pools.YT_QIAVAX_28_DEC_2023_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_XJOE_X_USDC,
    inputTokenAddress: pools.YT_XJOE_30_JUN_2022_X_USDC,
    contractType: 'PendleLiquidityMining',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.YT_WMEMO_X_MIM,
    inputTokenAddress: pools.YT_WMEMO_24_FEB_2022_X_MIM,
    contractType: 'PendleLiquidityMining',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
]
