import { mainnet } from '../../constants'

const tokens = mainnet.TOKENS
const liq = mainnet.LIQ

export default [
  {
    address: liq.PENDLE,
    inputTokenAddress: tokens.PENDLE,
    stakablePlatforms: ['pendle'],
    contractType: 'PendleSingleStaking',
    network: 'mainnet',
    rewardTokenAddresses: [tokens.PENDLE],
  },
]
