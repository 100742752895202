import { StakingPool as SdkStakingPool } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'
import Token from '@/domains/entities/Token'
import StakingPool from '@/domains/entities/StakingPool'
import TokenAmount from '@/domains/entities/TokenAmount'

export default class StakingPoolCollectionContract {
  constructor({ pools, signer, provider }) {
    this.pools = pools
    this.signer = signer
    this.provider = provider
  }

  fetchAccruingRewards(wallet) {
    return this.#sdkStakingPoolMethods().fetchAccruingRewards(wallet)
  }

  fetchVestedRewards(wallet) {
    return this.#sdkStakingPoolMethods().fetchVestedRewards(wallet)
  }

  fetchClaimableYields(wallet) {
    return new Promise((resolve, reject) => {
      this.#sdkStakingPoolMethods()
        .fetchClaimableYields(wallet)
        .then((response) => {
          resolve(
            response
              .map((yieldInfo) => ({
                origin: {
                  token: Token.query().find(yieldInfo.inputToken.address),
                  source: StakingPool.query().find(
                    `${yieldInfo.address}-${yieldInfo.inputToken.address}-${
                      Token.query().find(yieldInfo.inputToken.address).expiry
                    }`
                  ),
                },
                yields: yieldInfo.yields.map((sdkYield) => ({
                  yield: TokenAmount.fromSdkTokenAmount(sdkYield.yield),
                  yieldType: sdkYield.yieldType,
                  acquisitionMethod: 'accrue',
                })),
              }))
              .filter((yieldInfo) => yieldInfo.origin.source)
          )
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  balancesOf(walletAddress) {
    return Promise.all(
      this.pools
        .toArray()
        .map((pool) =>
          pool.contract({ signer: this.signer, provider: this.provider }).balanceOf(walletAddress)
        )
    )
  }

  #sdkStakingPoolMethods() {
    return SdkStakingPool.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
