import Token from './Token'
import OtLpTokenCollection from '../collections/OtLpTokenCollection'
import PendleMarket from './PendleMarket'
import OtLpContract from '../contracts/OtLpContract'

export default class OtLpToken extends Token {
  static query() {
    return new OtLpTokenCollection(
      ...Token.query()
        .where('tokenCategories', (val) => (val || []).includes('lp'))
        .where(
          'address',
          PendleMarket.query()
            .where('tokenPair', (tokenPair) =>
              tokenPair
                .map((token) => token.tokenCategories)
                .flat()
                .includes('ot')
            )
            .map((market) => market.address)
        )
        .toArray()
        .map((token) => new OtLpToken(token))
    )
  }

  contract({ signer, provider }) {
    return new OtLpContract({ otLp: this, signer, provider })
  }
}
