import QueryCollection from '../QueryCollection'
import StakingPoolCollectionContract from '@/domains/contracts/StakingPoolCollectionContract'
import StakingPool from '@/domains/entities/StakingPool'
import Token from '@/domains/entities/Token'
import stakingPools from '@/app-config/staking-pools'

export default class StakingPoolCollection extends QueryCollection {
  static primaryKey = 'id'

  static all() {
    return new StakingPoolCollection(
      ...stakingPools.map(
        (pool) =>
          new StakingPool({
            ...pool,
            inputToken: Token.query().find(pool.inputTokenAddress),
            abiInfo: {
              name: pool.contractType,
              abi: pool.abi,
            },
            rewardTokens: Token.query().where('address', pool.rewardTokenAddresses),
            interestTokens: Token.query().where('address', pool.interestTokenAddresses),
            stakableProtocol: pool.stakablePlatforms,
            address: pool.address.toLowerCase(),
            inputTokenAddress: pool.inputTokenAddress.toLowerCase(),
          })
      )
    )
  }

  contract({ signer, provider }) {
    return new StakingPoolCollectionContract({ pools: this, signer, provider })
  }
}
