export default class OtLpTokenCollectionContract {
  constructor({ otLpCollection, provider, signer }) {
    this.otLpCollection = otLpCollection
    this.provider = provider
    this.signer = signer
  }

  fetchRewards(walletAddress) {
    return Promise.all(this.#marketContracts().map((market) => market.fetchRewards(walletAddress)))
  }

  #marketContracts() {
    return this.otLpCollection
      .toArray()
      .map((otLp) => otLp.contract({ signer: this.signer, provider: this.provider }))
  }
}
