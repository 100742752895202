import { PendleMerkleDistributor } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'

export default class MerkleDistributor {
  constructor({ chainId }) {
    this.chainId = chainId
  }

  contract({ signer, provider }) {
    const merkleDistributor = new PendleMerkleDistributor()
    const methods = merkleDistributor.methods({ signer, provider, chainId: sdkChainId })

    return {
      async rewardAPR(LPToken) {
        return methods
          .rewardAPR(LPToken)
          .then((apr) => {
            return [{ origin: 'pendle', apr }]
          })
          .catch(() => {
            return [{ origin: 'pendle', apr: 0 }]
          })
      },
    }
  }
}
