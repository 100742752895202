import { avalanche } from '../../constants'

const pools = avalanche.POOLS
const tokens = avalanche.TOKENS
const liq = avalanche.LIQ

export default [
  {
    address: liq.OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    inputTokenAddress: pools.OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    contractType: 'PendleLiquidityMiningV2',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.OT_QIUSDC_28_DEC_2023_X_USDC,
    inputTokenAddress: pools.OT_QIUSDC_28_DEC_2023_X_USDC,
    contractType: 'PendleLiquidityMiningV2',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.OT_QIAVAX_28_DEC_2023_X_USDC,
    inputTokenAddress: pools.OT_QIAVAX_28_DEC_2023_X_USDC,
    contractType: 'PendleLiquidityMiningV2',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.OT_XJOE_30_JUN_2022_X_USDC,
    inputTokenAddress: pools.OT_XJOE_30_JUN_2022_X_USDC,
    contractType: 'PendleLiquidityMiningV2',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
  {
    address: liq.OT_WMEMO_24_FEB_2022_X_MIM,
    inputTokenAddress: pools.OT_WMEMO_24_FEB_2022_X_MIM,
    contractType: 'PendleLiquidityMiningV2',
    network: 'avalanche',
    rewardTokenAddresses: [tokens.PENDLE],
    stakablePlatforms: ['pendle'],
  },
]
