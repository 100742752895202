import { UniForkMarket as SdkMarket } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'
import TokenAmount from '@/domains/entities/TokenAmount'
import Token from '@/domains/entities/Token'
import Wallet from '@/domains/entities/Wallet'

export default class OtLpContract {
  constructor({ otLp, provider, signer }) {
    this.otLp = otLp
    this.provider = provider
    this.signer = signer
  }

  fetchRewards(walletAddress) {
    return new Promise((resolve, reject) => {
      SdkMarket.methods({
        signer: this.signer,
        provider: this.provider,
        chainId: sdkChainId,
      })
        .fetchClaimableRewardsFromOTMarkets(walletAddress)
        .then((tokenAmounts) => {
          resolve({
            origin: {
              token: Token.query().find(this.otLp.address),
              source: new Wallet({ address: walletAddress }),
            },
            yields: tokenAmounts.map((tokenAmount) => ({
              yield: TokenAmount.fromSdkTokenAmount(tokenAmount),
              yieldType: 'reward',
              acquisitionMethod: 'distribution',
            })),
          })
        })
        .catch((error) => reject(error))
    })
  }
}
